<template>
  <div class="container-fluid contact">
    <div class="container pt-5 pb-5">

      <div class="row">
        <div class="col-12 order-1 col-md-6 order-md-0">

          <hr class="d-block d-md-none my-4"/>

          <h3>Doncaster HQ</h3>
          <address>Believe House<br/>Heavens Walk<br/>Doncaster<br/>DN4 5HZ</address>


          <h3>Stockport HQ</h3>
          <address>Alexander House<br/>4 Station Road<br/>Cheadle Hulme<br/>Cheadle<br/>SK8 5AE</address>

          <p>
            Alternatively, you can contact us by email at<br/><a class="text-primary text-decoration-none" :href="`mailto:${enquires[0].email}`">{{ enquires[0].email }}</a>
          </p>

        </div>
        <form class="col-12 order-0 col-md-6 order-md-0 needs-validation" :class="{ 'was-validated': validated}">

          <h2 class="text-primary">How can we help?</h2>

          <div class="mb-3">
            <label for="enquiry" class="form-label">Enquiry</label>
            <select id="enquiry" class="form-select bg-form" v-model="enquiry.type">
              <option v-for="e in enquires" :key="e.title">{{ e.title }}</option>
            </select>
          </div>

          <div class="mb-3">
            <label for="name" class="form-label">Name</label>
            <input id="name"
                   type="text"
                   :required="enquiry.name.length == 0"
                   class="form-control bg-form"
                   v-model="enquiry.name">
          </div>

          <div class="mb-3">
            <label for="email" class="form-label">Email</label>
            <input id="email"
                   type="email"
                   :required="enquiry.email.length == 0"
                   class="form-control bg-form"
                   v-model="enquiry.email">
          </div>

          <div class="mb-3">
            <label for="message" class="form-label">Message</label>
            <textarea id="message"
                      class="form-control mb-3 bg-form"
                      rows="6"
                      :required="enquiry.message.length == 0"
                      v-model="enquiry.message"></textarea>
          </div>

          <div v-if="validated && invalid" class="alert alert-footer mb-3" role="alert">
            Please complete all fields.
          </div>

          <a
              class="btn btn-bmg icon-right d-flex d-md-inline-block"
              @click="sendEnquiry"
          >Submit <i class="bi bi-caret-right-fill"/></a>

        </form>
      </div>
    </div>

    <div ref="enquirySent" class="modal" tabindex="-1">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-body text-center pt-5">
            <p>Thank you!</p>
            <p>You enquiry has been sent and we will be in touch.</p>
          </div>
          <div class="modal-footer justify-content-center">
            <button type="button" class="btn btn-primary" data-bs-dismiss="modal" @click="enquirySentModel.hide()">Close</button>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
const enquires = [
  {
    title: "General Enquiry",
    email: "info@believemoney.com"
  },
  {
    title: "Join The Team",
    email: "recruitment@believemoney.com"
  }
];

import { Modal } from 'bootstrap'

export default {
  name: "ContactSection",
  data() {
    return {
      enquires,
      enquiry: {
        type: enquires[0].title,
        name: "",
        email: "",
        message: ""
      },
      validated: false,
      invalid: false,
      enquirySentModel: null
    }
  },
  mounted() {
    this.enquirySentModel = new Modal(this.$refs.enquirySent)
  },
  methods: {
    sendEnquiry() {
      this.validated = true;
      this.invalid = false;
      if (this.enquiry.name.length > 0 && this.enquiry.email.length > 0 && this.enquiry.message.length > 0) {
        // Send enquiry through API
        const payload = {
          type: this.enquiry.type,
          name: this.enquiry.name,
          email: this.enquiry.email,
          message: this.enquiry.message
        };

        try {
          fetch("https://eoetep70ytn0xnv.m.pipedream.net", {method: "POST", body: JSON.stringify(payload)});
        } catch {
          // no-op
        }

        // Reset form
        this.enquiry = {
          type: enquires[0].title,
          name: "",
          email: "",
          message: ""
        };
        // Reset validated
        this.validated = false;
        // Show enquiry sent modal
        this.enquirySentModel.show()
      } else {
        this.invalid = true
      }
    }
  }
}
</script>

<style scoped lang="scss">
</style>
